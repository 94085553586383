<template>
    <v-container v-if="$store.state.user.components.includes(46)">
        <v-card>
            <v-card-text>
                <crud model="Facility" :headers="headers" :conditions="conditions" label="Facilities">
                    <template v-slot:item.link="obj">
                        <v-btn outlined text color="primary" :to="'/facility/'+obj.item.id">Profile</v-btn>
                    </template>
                </crud>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD.vue";
export default {
    components: {
        crud,
    },
    created() {
        this.headers = [
            {
                value: "link",
                text: "Link",
                query: false,
                slot: true,
                visible: this.$store.state.user.components.includes(47),
            },

            {
                value: "id",
                text: "ID",
                visible: false,
                editable: false,
                isId: true,
            },
            { value: "name", text: "Name", mandatory: true, searchable: true },
            {
                value: "type",
                text: "Type",
                mandatory: true,
                searchable: true,
                type: "select",
                items: ["Department", "Client", "Clinic", "Laboratory"],
            },
            {
                value: "role",
                text: "Role",
                mandatory: true,
                type: "select",
                items: ["Sender", "Receiver", "N/A"],
            },
            {
                value: "state",
                text: "State",
                searchable: true,
                type: "select",
                items: this.$store.state.usStatesAbrv,
            },
            {
                value: "status",
                text: "Status",
                mandatory: true,
                type: "select",
                items: ["Active", "Inactive"],
            },
        ];
    },
    data() {
        return {
            headers: [],
            conditions: [{id:{in:this.$store.state.user.payload.ls}}]
        };
    },
};
</script>